<script setup lang="ts">
import XHeader from "@ui/XHeader.vue";
import XStep from "@ui/XStep.vue";
import { onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { API_URL } from "@config/core";
import XCard from "@ui/XCard.vue";
import XBlockBar from "@ui/XBlockBar.vue";

type CardSet = {
  id: number;
  name: string;
  cards: number[];
  userSelection: number[];
  requiredCardsNumber: number;
  remainingCardSelectionsLeft: number;
  showCardsFaceUp: boolean;
  isDone: boolean;
};

type CustomerSession = {
  sessionId?: string;
  cardSet?: CardSet | null;
  areAllCardSetsProcessed?: boolean;
};

const route = useRoute();
const session = ref<CustomerSession>();
// const selectedIndex = ref([]);
const intervalCounter = ref(0);

// on load
watch(() => route.params.sessionId as string, loadSessionById, {
  immediate: true,
});

// stop interval after one hour if seesion is not done
watch(session, (newSession) => {
  if (newSession?.areAllCardSetsProcessed) {
    stopInterval();
  }
});

// stop interval after one hour if seesion is not done
watch(intervalCounter, (newIntervalCounter) => {
  if (newIntervalCounter >= 0) {
    stopInterval();
  }
});

async function loadSessionById(sessionId: string) {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const res = await fetch(`${API_URL}/customer/session/${sessionId}`, {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
      credentials: "include",
    });

    const response = await res.json();
    console.log("🚀 updated session value ", response);
    session.value = response;

    return;
  } catch (e) {
    console.error("handleLoadSessionById ", e);
  }
}

async function handleSelectSetCard(card: number, index: number) {
  console.log(index);
  try {
    // selectedIndex.value = index;
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const res = await fetch(
      `${API_URL}/customer/session/${session?.value?.sessionId}/select-card/${card}`,
      {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        credentials: "include",
      }
    );

    await res.json();
  } catch (error) {
    console.error(error);
  }
  return;
}

let clientInterval: ReturnType<typeof setInterval>;

function startInterval() {
  clientInterval = setInterval(async () => {
    console.log("setInterval is here");
    if (session.value?.areAllCardSetsProcessed === false) {
      await loadSessionById(route.params.sessionId as string);
    }
  }, 1000);
}

function stopInterval() {
  clearInterval(clientInterval);
  // selectedIndex.value = -1;
  console.log("setInterval is stoped");
}

onMounted(() => {
  startInterval();
});
</script>

<template>
  <XHeader title="Сеанс" />

  <div v-if="!session?.cardSet">
    <span class="loading-text">
      Підготовка до початку сеансу<span class="dots"></span>
    </span>
  </div>

  <XStep v-if="session?.cardSet?.userSelection.length" class="card-rows">
    <XBlockBar style="position: absolute; top: -0.5rem">Обрані карти</XBlockBar>

    <div
      v-for="(item, index) in session?.cardSet?.userSelection"
      v-on.once="true ? { click: () => handleSelectSetCard(item, index) } : {}"
      class="card"
    >
      <XCard
        v-if="
          session?.cardSet?.showCardsFaceUp ||
          session?.cardSet?.userSelection?.includes(item)
        "
        :src="`/cards/${session?.cardSet?.id}/${item}.jpg`"
      />

      <div v-else class="card-back">&nbsp;</div>
    </div>
  </XStep>

  <XStep v-if="session?.cardSet" class="card-rows">
    <div
      v-for="(item, index) in session?.cardSet?.cards?.filter(
        (card) => !session?.cardSet?.userSelection?.includes(card)
      )"
      v-on.once="true ? { click: () => handleSelectSetCard(item, index) } : {}"
      class="card"
    >
      <XCard
        v-if="
          session?.cardSet?.showCardsFaceUp ||
          session?.cardSet?.userSelection?.includes(item)
        "
        :src="`/cards/${session?.cardSet?.id}/${item}.jpg`"
      />

      <div v-else class="card-back">&nbsp;</div>
    </div>
  </XStep>
</template>

<style scoped lang="scss">
@use "src/scss/vars" as *;

.card-rows {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 1rem;
  position: relative;
}

.card {
  cursor: pointer;
  border: 2px solid transparent;
  padding: 2px;
  transition: all 300ms ease-in-out;

  &:hover {
    box-shadow: 0 0 10px red;
    transition: all 300ms ease-in-out;
  }
}

.card-image {
  width: 100%;
  height: auto;
  filter: none;
  -webkit-filter: grayscale(0);
  transition: all 300ms ease-in-out;

  &-disabled {
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
    opacity: 0.5;
  }
}

.card-back {
  aspect-ratio: 230/315;
  width: 100%;
  height: auto;
  background-color: $blue;
}

@keyframes dots {
  0% {
    content: ".";
  }
  33% {
    content: "..";
  }
  66% {
    content: "...";
  }
}
.dots::after {
  content: ".";
  animation: dots 1.5s infinite;
}
.loading-text {
  display: inline-block;
  padding: 0 1rem;
}
</style>
