<script setup lang="ts">
import { ref, reactive, onMounted, watch } from "vue";
import XCheckbox from "@ui/XCheckbox.vue";
import XInput from "@ui/XInput.vue";
import XRange from "@ui/XRange.vue";
import { ButtonNavigation } from "@type/setBuilderFrame";

defineProps<{
  cardsNumber: number;
}>();

const emit = defineEmits<{
  (e: "required-number", buttonNavigation: ButtonNavigation): void;
}>();

const buttonNavigation = reactive<ButtonNavigation>({
  back: {
    requiredNumber: 0,
    showCardsFaceUp: null,
  },
  next: {
    requiredNumber: 0,
    showCardsFaceUp: null,
  },
});

const requiredNumber = ref(1);
const showCardsFaceUp = ref(true);
const cardsQuantityUnknown = ref();

onMounted(() => {
  buttonNavigation.next.requiredNumber = requiredNumber.value;
  buttonNavigation.next.showCardsFaceUp = showCardsFaceUp.value;

  emit("required-number", buttonNavigation);

  return;
});

watch(requiredNumber, (newValue) => {
  buttonNavigation.next.requiredNumber = newValue;
  buttonNavigation.next.showCardsFaceUp = showCardsFaceUp.value;

  emit("required-number", buttonNavigation);
});

function cardsQuantityUnknownHandle() {
  buttonNavigation.next.requiredNumber = null;
  buttonNavigation.next.showCardsFaceUp = showCardsFaceUp.value;

  emit("required-number", buttonNavigation);

  return;
}

function showCardsFaceUpHandle() {
  buttonNavigation.next.requiredNumber = requiredNumber.value;
  buttonNavigation.next.showCardsFaceUp = true;

  emit("required-number", buttonNavigation);

  return;
}
</script>

<template>
  <div class="label">потрібно обрати карт</div>

  <div class="range">
    <div class="range__number-cell">
      <XInput
        type="number"
        name="rangeInput"
        v-model:value="requiredNumber"
        :disabled="cardsNumber === 2"
      />
    </div>
    <div v-if="cardsNumber > 2" class="range__manual-cell">
      <div>від 1</div>
      <XRange
        :min="1"
        :max="cardsNumber - 1"
        :disabled="cardsNumber === 2"
        v-model:value="requiredNumber"
        class="range__manual-input"
      />
      <div>до {{ cardsNumber - 1 }}</div>
    </div>
  </div>

  <div class="label">або</div>
  <XCheckbox
    label="кількість карт для вибору невідома"
    v-model:value="cardsQuantityUnknown"
    @click="cardsQuantityUnknownHandle"
    style="margin-bottom: 1rem"
  />

  <XCheckbox
    label="показувати карти у відкриту"
    v-model:value="showCardsFaceUp"
    @click="showCardsFaceUpHandle"
  />
</template>

<style scoped lang="scss">
@use "/src/scss/vars" as *;

.range {
  display: flex;
  flex-direction: column;

  @include screen($md) {
    flex-direction: row;
  }
}

.range__manual-input {
  width: 100%;
}

.range__manual-cell {
  display: flex;
  column-gap: 1rem;
  align-items: center;
  font-size: $font-size-small;
  font-weight: bold;
  text-wrap: nowrap;
  margin-bottom: 1rem;
}

.label {
  text-align: center;
  margin-bottom: 1rem;
  font-weight: bold;

  @include screen($md) {
    text-align: left;
  }
}
</style>
