<script setup lang="ts">
import XHeader from "@ui/XHeader.vue";
import Footer from "../components/footer/Footer.vue";
import XContent from "@ui/XContent.vue";
import XWhiteBlock from "@ui/XWhiteBlock.vue";
import XBlockBar from "@ui/XBlockBar.vue";
import XButton from "@ui/XButton.vue";

const rationalPackageUrl = "https://secure.wayforpay.com/button/b3382a0b02229";
const standardPackageUrl = "https://secure.wayforpay.com/button/b5c2cec4203b4";

function handlePackage(location: string) {
  const newWindow = window.open("", "_blank");
  if (newWindow) {
    newWindow.location = location;
  }
}
</script>

<template>
  <div
    style="display: flex; flex-direction: column; height: calc(100vh - 3rem)"
  >
    <div style="flex: 1">
      <XHeader title="Ціни" />

      <XContent class="layout" style="padding-top: 1rem">
        <XWhiteBlock class="price" style="padding: 1rem; position: relative">
          <XBlockBar style="position: absolute; top: -0.5rem"
            >Рекомендовано</XBlockBar
          >

          <h3 class="package-title">Раціональний пакет</h3>

          <ul class="package-list">
            <li>Консруктор сеансів</li>
            <li>Початковий набір колод</li>
            <li>Необмежена кількість сеансів</li>
          </ul>
          <div class="previous-price"><s>2400 ₴ на рік</s></div>
          <div class="current-price">1200 ₴ за пів року</div>
          <div>це 200 ₴ на місяць</div>
          <div class="price-description">
            економія при оплаті за більший час
          </div>
          <div>
            Будь-ласка напишіть нам перед оплатою на
            <a href="mailto:tam@koloneba.com">tam@koloneba.com</a>
          </div>
          <br />
          <XButton
            title="Перейти до оплати"
            @click="handlePackage(rationalPackageUrl)"
          />
        </XWhiteBlock>

        <XWhiteBlock class="price" style="padding: 1rem">
          <h3 class="package-title">Стандартний пакет</h3>

          <ul class="package-list">
            <li>Консруктор сеансів</li>
            <li>Початковий набір колод</li>
            <li>Необмежена кількість сеансів</li>
          </ul>
          <div class="current-price">1500 ₴ за три місяці</div>
          <div>це 500 ₴ на місяць</div>
          <div class="price-description">стандартний підхід без економії</div>
          <div>
            Будь-ласка напишіть нам перед оплатою на
            <a href="mailto:tam@koloneba.com">tam@koloneba.com</a>
          </div>
          <br />
          <XButton
            title="Перейти до оплати"
            @click="handlePackage(standardPackageUrl)"
          />
        </XWhiteBlock>
      </XContent>
    </div>

    <Footer />
  </div>
</template>

<style scoped lang="scss">
@use "src/scss/vars" as *;

.layout {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 1rem;

  @include screen($md) {
    flex-direction: row;
    column-gap: 1rem;
  }
}

.price {
  max-width: 350px;
}

.package-title {
  font-size: 1.5rem;
  margin: 0 0 1rem 0;
}

.package-list {
  padding-left: 0;
  list-style-type: none;

  li {
    position: relative;
    padding-left: 1.5rem;
    margin-bottom: 1rem;

    &:before {
      position: absolute;
      left: 0;
      content: "—";
    }
  }
}

.previous-price {
  color: $gray-2;
  font-weight: bold;
}

.current-price {
  font-size: 2rem;
  font-weight: bold;
}

.price-description {
  color: $gray-2;
  margin: 0.5rem 0 2rem 0;
}
</style>
