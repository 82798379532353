<script setup lang="ts">
import { computed, reactive, ref } from "vue";
import { useRouter } from "vue-router";
import XInput from "@ui/XInput.vue";
import XHeader from "@ui/XHeader.vue";
import XButton from "@ui/XButton.vue";
import XFormBorder from "@ui/XFormBorder.vue";
import { storeToRefs } from "pinia";
import XError from "@ui/XError.vue";
import XTransitionFadeIn from "@ui/XTransitionFadeIn.vue";
import { useAuthStore } from "@store/useAuthStore";
import { useErrorStore } from "@store/useErrorStore";
import { API_URL } from "@config/core";

const { setAuth } = useAuthStore();
const router = useRouter();

const errorStore = useErrorStore();
const { error } = storeToRefs(errorStore);
const { setRegisterError } = errorStore;

const isSubmitDisabled = ref(false);

const form = reactive({
  email: "",
  password: {
    password: "",
    confirm_password: "",
  },
});

const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

const formEmailIsValid = computed(() => {
  if (!form.email) return false;
  return emailRegex.test(form.email);
});

async function handleSubmit() {
  try {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const requestOptions: RequestInit = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(form),
      redirect: "follow",
      credentials: "include" as RequestCredentials,
    };

    // @ts-ignore
    const res = await fetch(`${API_URL}/register`, requestOptions)
      .then((response) => {
        return response.json();
      })
      .catch((error) => console.log("error", error));
    console.log("🚀 ~ handleSubmit ~ res:", res);

    if (
      res?.email.length > 0 ||
      res?.password.length > 0 ||
      res?.confirm_password.length > 0
    ) {
      setRegisterError(true, res);
      setAuth(false);
    } else {
      setAuth(true);
      setRegisterError(false);
      router.push("/"); // or to push route from local storage
    }
  } catch (error) {
    console.error(error);
  }
}
</script>

<template>
  <XHeader title="Реєстрація" />

  <XTransitionFadeIn v-if="error?.register?.value">
    <XError v-for="message in error.register?.message" class="error-message">{{
      message
    }}</XError>
  </XTransitionFadeIn>

  <XFormBorder>
    <XInput
      v-model:value="form.email"
      name="email"
      type="email"
      :label="
        formEmailIsValid ? 'Електронна пошта &check;' : 'Електронна пошта'
      "
    />

    <XInput
      v-model:value="form.password.password"
      name="password"
      type="password"
      label="Пароль"
    />

    <XInput
      v-model:value="form.password.confirm_password"
      name="confirmPassword"
      type="password"
      label="Ще раз пароль"
    />

    <XButton
      :disabled="
        !(
          form.email?.length > 0 &&
          form.password.password?.length > 0 &&
          form.password.password?.length ===
            form.password.confirm_password?.length
        ) || isSubmitDisabled
      "
      title="Відправити"
      @click.prevent="handleSubmit"
    />
  </XFormBorder>
</template>

<style scoped></style>
