import { createWebHistory, createRouter } from "vue-router";
import HomePage from "@pages/HomePage.vue";
import SignUpPage from "@pages/SignUpPage.vue";
import SignInPage from "@pages/SignInPage.vue";
import Sessions from "@pages/Sessions.vue";
import Session from "@pages/Session.vue";
import Prices from "@pages/Prices.vue";
import NewSession from "@pages/NewSession.vue";
import CookiePolicy from "@pages/CookiePolicy.vue";
import PrivacyPolicy from "@pages/PrivacyPolicy.vue";
import TermsAndConditions from "@pages/TermsAndConditions.vue";
import CustomerSession from "@pages/CustomerSession.vue";
import UserView from "@views/UserView.vue";
import PublicView from "@views/PublicView.vue";
import CustomerView from "@views/CustomerView.vue";
import ResetPasswordPage from "@pages/ResetPasswordPage.vue";

const routes = [
  {
    path: "/",
    component: PublicView,
    children: [
      { path: "", component: HomePage },
      { path: "sign-up", component: SignUpPage },
      { path: "sign-in", component: SignInPage },
      { path: "reset-password/", component: ResetPasswordPage },
      { path: "reset-password/:token", component: ResetPasswordPage },
      { path: "prices", component: Prices },
      { path: "cookie-policy", component: CookiePolicy },
      { path: "privacy-policy", component: PrivacyPolicy },
      { path: "terms-and-conditions", component: TermsAndConditions },
    ],
  },
  {
    path: "/user",
    component: UserView,
    children: [
      { path: "sessions", component: Sessions },
      { path: "session/:id", component: Session },
      { path: "new-session", component: NewSession },
    ],
  },
  {
    path: "/customer",
    component: CustomerView,
    children: [
      {
        path: "session/:sessionId",
        name: "CustomerSession",
        component: CustomerSession,
      },
    ],
  },
];

export const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(_to, _from, savedPosition) {
    if (savedPosition) {
      return savedPosition; // For back/forward navigation
    } else {
      return { top: 0 }; // Scroll to top
    }
  },
});
