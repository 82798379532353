import { API_URL } from "@config/core";
import { SessionSet } from "@type/session";
import { useLocalStorage } from "@vueuse/core";
import { router } from "src/router";
import { Reactive } from "vue";

export default function useNewSession() {
  async function handleSubmitSession(session: Reactive<SessionSet[]>) {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append("Content-Type", "application/json");

      const res = await fetch(`${API_URL}/session`, {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({ cardSets: session }),
        redirect: "follow",
        credentials: "include",
      });

      const response = await res.text();
      const responseParse = await JSON.parse(response);

      if (responseParse?.sessionId) {
        router.push("/user/session/" + responseParse?.sessionId);
      }

      if (responseParse?.code === 401) {
        useLocalStorage("session", JSON.stringify(session));
        localStorage.removeItem("auth");
        router.push("/sign-in");
      }
    } catch (error) {
      console.error(error);
    }
    return;
  }
  return { handleSubmitSession };
}
