<script setup lang="ts">
import { computed, reactive, ref } from "vue";
import XInput from "@ui/XInput.vue";
import XHeader from "@ui/XHeader.vue";
import XButton from "@ui/XButton.vue";
import XFormBorder from "@ui/XFormBorder.vue";
import { useAuthStore } from "@store/useAuthStore.ts";
import { useErrorStore } from "@store/useErrorStore";
import { useRouter } from "vue-router";
import { storeToRefs } from "pinia";
import XError from "@ui/XError.vue";
import XTransitionFadeIn from "@ui/XTransitionFadeIn.vue";
import { API_URL } from "@config/core";
import useNewSession from "@composables/useNewSession";

const { setAuth } = useAuthStore();
const router = useRouter();

const errorStore = useErrorStore();
const { error } = storeToRefs(errorStore);
const { setAuthError } = errorStore;

const isSubmitDisabled = ref(false);

const form = reactive({
  username: "",
  password: "",
});

const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

const formEmailIsValid = computed(() => {
  if (!form.username) return false;
  return emailRegex.test(form.username);
});

const { handleSubmitSession } = useNewSession();

async function handleSubmit() {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(form),
      redirect: "follow",
      credentials: "include",
    };

    isSubmitDisabled.value = true;

    // @ts-ignore
    const res = await fetch(`${API_URL}/login`, requestOptions)
      .then((response) => {
        return response;
      })
      .catch((error) => console.log("error", error));

    // if res and seesion is in local storage

    const localStorageSession = localStorage.getItem("session");

    if (res?.ok && localStorageSession && localStorageSession.length > 0) {
      const session = JSON.parse(localStorageSession);
      localStorage.removeItem("session");
      setAuthError(false);
      setAuth(true);
      handleSubmitSession(session);

      return;
    }

    if (res?.ok) {
      setAuthError(false);
      setAuth(true);
      router.push("/");
    } else {
      setAuth(false);
      setAuthError(true, "Неправильний логін або пароль");
      isSubmitDisabled.value = false;
    }
  } catch (error) {
    console.error(error);
  }
}
</script>

<template>
  <XHeader title="Авторизація" />

  <XTransitionFadeIn v-if="error?.auth?.value">
    <XError class="error-message">
      {{ error.auth?.message }}
    </XError>
  </XTransitionFadeIn>

  <XFormBorder>
    <div>
      Станом на зараз тільки користувачі з <b>активованими</b> акаунтами мають
      можливість авторизуватись
    </div>

    <br />

    <XInput
      v-model:value="form.username"
      name="login"
      type="text"
      :label="
        formEmailIsValid ? 'Електронна пошта &check;' : 'Електронна пошта'
      "
    />

    <XInput
      v-model:value="form.password"
      name="password"
      type="password"
      label="Пароль"
    />

    <XButton
      title="Увійти"
      :disabled="
        !(form.username?.length > 0 && form.password?.length > 0) ||
        isSubmitDisabled
      "
      @click="handleSubmit"
    />

    <div style="margin-top: 2rem">
      <router-link to="/reset-password">Відновити пароль</router-link>
    </div>
  </XFormBorder>
</template>
