<script setup lang="ts">
import XHeader from "@ui/XHeader.vue";
import Footer from "@footer/Footer.vue";
import XContent from "@ui/XContent.vue";
import markdownit from "markdown-it";
import markdownContent from "@markdown/privacy-policy.md?raw";

const md = (markdownit as any)();
const result = md.render(markdownContent);
</script>

<template>
  <XHeader title="Політика конфіденційності" />

  <XContent>
    <div v-html="result" />
  </XContent>

  <Footer />
</template>

<style scoped lang="scss"></style>
