<script setup lang="ts">
import XHeader from "@ui/XHeader.vue";
import XContent from "@ui/XContent.vue";
import Footer from "@footer/Footer.vue";
import markdownit from "markdown-it";
import markdownContent from "@markdown/terms-and-conditiones.md?raw";

const md = (markdownit as any)();
const result = md.render(markdownContent);
</script>

<template>
  <XHeader title="Публічна оферта" />
  <XContent>
    <div v-html="result" />
  </XContent>

  <Footer />
</template>
