import { createApp } from "vue";
import { createPinia } from "pinia";
import { router } from "./router/index";
import "./scss/index.scss";
// import VueGtag from "vue-gtag";
import App from "./App.vue";

const pinia = createPinia();
const app = createApp(App);

// app.use(
//   VueGtag,
//   {
//     config: { id: "G-0MMR5FK29L" },
//   },
//   router
// );

app.use(pinia);
app.use(router);
app.mount("#app");
