<script setup lang="ts"></script>

<template>
  <footer class="footer">
    <div>&copy; 2024 &ndash; &infin;</div>
    <div>
      <ul>
        <li>
          <RouterLink to="/terms-and-conditions">Публічна оферта</RouterLink>
        </li>
        <li>
          <RouterLink to="/cookie-policy"
            >Політика щодо файлів cookies (Cookie Policy)</RouterLink
          >
        </li>
        <li>
          <RouterLink to="/privacy-policy"
            >Політика конфіденційності</RouterLink
          >
        </li>
      </ul>
    </div>
  </footer>
</template>

<style scoped lang="scss">
.footer {
  display: flex;
  padding: 0 1rem;
  column-gap: 2rem;
  margin-top: 3rem;
  margin-bottom: 5rem;

  & > :first-child {
    min-width: 120px;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
      margin-bottom: 1rem;
    }
  }
}
</style>
